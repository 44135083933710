import { render, staticRenderFns } from "./TabForms.vue?vue&type=template&id=5451af02&scoped=true&"
import script from "./TabForms.vue?vue&type=script&lang=js&"
export * from "./TabForms.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5451af02",
  null
  
)

export default component.exports