<template>
    <b-table
        class="table-striped overflow-x-scroll"
        :data="forms"
        :striped="true"
        :mobile-cards="true"
        :sticky-header="false"
    >
        <b-table-column
            sortable
            field="id"
            label="ID"
            width="40"
            numeric
            v-slot="props"
        >
            {{ props.row.id }}
        </b-table-column>

        <b-table-column field="name" :label="$t('forms.form')" v-slot="props">
            {{ props.row.name }}
        </b-table-column>

        <b-table-column
            field="steps_count"
            :label="$t('forms.steps')"
            numeric
            v-slot="props"
        >
            {{ props.row.steps_count }}
        </b-table-column>

        <b-table-column
            field="attributes_count"
            numeric
            :label="$t('forms.attributes')"
            v-slot="props"
        >
            {{ props.row.attributes_count }}
        </b-table-column>

        <b-table-column
            field="attributes_count"
            numeric
            :label="$t('forms.tickets')"
            v-slot="props"
        >
            <router-link :to="`/posts?form_id=${props.row.id}`">
                <span class="cursor-pointer"> {{ props.row.posts_count }}</span>
            </router-link>
        </b-table-column>

        <b-table-column field="" :label="$t('forms.actions')">
            <div slot="default" slot-scope="props">
                <router-link
                    :to="`/forms/${props.row.id}`"
                    class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="currentColor"
                        class="h-4 w-4"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-width="2"
                            d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
                        />
                        <path
                            stroke-width="2"
                            d="M14 3v5h5M16 13H8M16 17H8M10 9H8"
                        />
                    </svg>
                </router-link>
                <router-link
                    :to="`/forms/${props.row.id}/edit`"
                    class="inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                    </svg>
                </router-link>
            </div>
        </b-table-column>
    </b-table>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: 'TabForms',
    data: function () {
        return {
            loading: false
        }
    },
    created() {
        this.getForms()
        this.data = this.forms
    },
    computed: {
        ...mapState('form', {
            forms: (state) => state.forms,
            config: (state) => state.formsConfig
        })
    },
    methods: {
        ...mapActions('form', ['getForms'])
    }
}
</script>

<style scoped></style>
