<template>
    <main id="js-page-content" class="page-content" role="main">
        <div class="subheader hidden-lg-up d-flex">
            <portal to="ariane">
                <h1 class="subheader-title mb-2 text-info">
                    <i class="fal fa-poll m-2 text-info"></i>
                    {{ $t('forms.mine') }}
                </h1>
                <!-- <p class="fw-500 m-5">Gérer vos formulaires</p> -->
            </portal>
        </div>

        <section
            v-if="!loading"
            class="p-3 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white"
        >
            <router-link
                :to="`/forms/create`"
                class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                </svg>
                {{ $t('forms.new') }}
            </router-link>
            <hr />
            <TabForms />
        </section>
    </main>
</template>

<script>
// import Panel from '@/components/UI/Panel'
import TabForms from '@/components/Model/Form/TabForms'

import { mapState, mapActions } from 'vuex'

export default {
    name: 'Forms',
    components: {
        // Panel,
        TabForms
    },
    created() {
        this.loadAsyncData()
    },
    data: function () {
        return {
            loading: true
        }
    },
    computed: {
        ...mapState('form', {
            forms: (state) => state.forms,
            types: (state) => state.types,
            config: (state) => state.formsConfig
        })
    },
    methods: {
        ...mapActions('form', ['getForms']),
        loadAsyncData() {
            this.loading = true
            this.getForms().then(() => {
                this.types.map((type) => {
                    type.forms = this.forms.filter(
                        (form) => form.form_type_id === type.id
                    )
                })
                this.loading = false
            })
        },
        onSort(field, order) {
            this.config.sort = field
            this.config.sortDirection = order

            this.loadAsyncData()
        },
        onFilterChange(filter) {
            var filters = {}
            Object.keys(filter).forEach((element) => {
                filters[element] = filter[element]
            })
            this.config.filters = filters

            this.loadAsyncData()
        },
        onPageChange(page) {
            this.config.page = page

            this.loadAsyncData()
        }
    }
}
</script>

<style scoped></style>
